<template>
  <div style="width: 100%; height: calc(100vh - 170px);" class="row">
    <tracker-map ref="trackerMap" v-if="show" height="100" width="100"></tracker-map>
  </div>
</template>
<script>
import TrackerMap from '../../../components/map.vue'
export default {
  components: { TrackerMap },
  name: 'Map',
  data(){
      return {
        token: null,
        show: true
      }
  },
  mounted(){

    

  },
  methods: {
    
  }
}
</script>